/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Input, Table, Tooltip } from 'antd'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import BButton from '../../../components/BButton'
import CustomBadge from '../../../components/CustomBadge'
import CustomPagination from '../../../components/CustomPagination'
import UserService from '../../../services/UserService'
import EditUser from './FormSection/EditUser'

const { Search } = Input

const UserList = () => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const [modal, setModal] = useState({
    isEditUserModalOpen: false,
  })
  const [selectedRow, setSelectedRow] = useState(null)
  const [data, setData] = useState([])
  const [total, setTotal] = useState(0)
  const [params, setParams] = useState({
    page: 1,
    per_page: 10,
    sort: 'id',
    order: 'desc',
    filter: '',
  })

  useEffect(() => {
    setStateParams()
  }, [])

  useEffect(() => {
    getData()
  }, [params])

  const getData = async () => {
    setLoading(true)

    try {
      const response = await UserService.getDatatable(params)

      if (response.status === 200) {
        setLoading(false)
        setData(response.data)
        setTotal(response.meta.total)
        setQueryString()
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const onSearch = (filter) => setParams({ ...params, filter })

  const onPageChange = (page, pageSize) => {
    const per_page = pageSize
    setParams({ ...params, page, per_page })
  }

  const onTableChange = (_pagination, _filters, sorter) => {
    let { columnKey: sort, order } = sorter
    sort = order ? sort : 'id' // jika order undefined default sort adalah column `id` dan order by `id` desc
    order = order === 'ascend' ? 'asc' : 'desc'
    setParams({ ...params, sort, order })
  }

  const setStateParams = () => {
    const qs = new URLSearchParams(window.location.search)
    setParams({
      page: qs.get('page') || params.page,
      per_page: qs.get('per_page') || params.per_page,
      sort: qs.get('sort') || params.sort,
      order: qs.get('order') || params.order,
      filter: qs.get('filter') || params.filter,
    })
  }

  const setQueryString = () => {
    const newParams = {}

    Object.keys(params).forEach((key) => {
      if (params[key]) {
        newParams[key] = params[key]
      }
    })

    const qs = new URLSearchParams(newParams)

    navigate({
      pathname: window.location.pathname,
      search: '?' + qs,
    })
  }

  const changeStatus = async (userId) => {
    try {
      setLoading(true)
      const response = await UserService.changeStatus(userId)
      if (response.status === 200) {
        setLoading(false)
        getData()
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  const toggleModal = (name) => {
    setModal((prev) => ({ ...prev, [name]: !prev[name] }))
  }

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      key: 'no',
      align: 'center',
    },
    {
      title: 'Nama',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      render: (text) => <li className="text-gray-700 font-semibold">{text}</li>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      sorter: true,
    },
    {
      title: 'Sebagai',
      dataIndex: 'role_name',
      key: 'role_name',
      sorter: true,
    },
    {
      title: 'Unit',
      dataIndex: 'unit_name',
      key: 'unit_name',
      sorter: false,
      render: (units) =>
        units.length === 4 ? (
          <>ALL</>
        ) : (
          units.map((unit) => (
            <>
              {unit} <br />
            </>
          ))
        ), // jika unit.length === 4 maka tampilkan ALL
    },
    {
      title: 'Level',
      dataIndex: 'level_name',
      key: 'level_name',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status, row) => {
        const iconButton =
          status === 'active'
            ? 'fa-solid fa-circle-check text-lg text-green-500'
            : status === 'inactive'
            ? 'fa-solid fa-circle-xmark text-lg text-red-500'
            : 'fa-solid fa-circle-exclamation text-lg'

        return (
          <Tooltip
            onClick={(e) => {
              changeStatus(row.id)
            }}
            placement="topLeft"
            title={status}
            arrow={{ arrowPointAtCenter: true }}
          >
            <i className={iconButton} />
          </Tooltip>
        )
      },
    },
    {
      title: 'Action',
      key: 'action',
      align: 'center',
      width: 150,
      render: (_, record) => (
        <div className="grid gap-2">
          <Button
            className="bg-yellow-500 border-0 text-white text-xs rounded-md"
            onClick={() => {
              setSelectedRow(record)
              toggleModal('isEditUserModalOpen')
            }}
          >
            <i className="fa-regular fa-pen-to-square mr-2" />
            Ubah
          </Button>{' '}
          <Button
            className="bg-rose-500 border-0 text-white text-xs rounded-md"
            // onClick={() => deleteUser(record.id)}
          >
            <i className="fa-solid fa-trash-can mr-2" /> Hapus
          </Button>
        </div>
      ),
    },
  ]

  return (
    <>
      <CustomBadge formOrder title="Tabel Pengguna" titleParent="Pengguna" />

      <div className="flex justify-between items-center ml-3">
        <div className="search-user">
          <Search
            className="md:w-80"
            placeholder="Search"
            allowClear
            size="medium"
            onSearch={onSearch}
          />
        </div>

        <BButton onClick={() => navigate('/users/add')}>Tambah</BButton>
      </div>

      <div className="bg-white rounded-lg my-5 ml-3">
        <div className="table-section-product relative w-full">
          <Table
            className="antialiased"
            rowKey="id"
            onChange={onTableChange}
            loading={loading}
            columns={columns}
            dataSource={data}
            pagination={false}
            scroll={{ x: true }}
            tableLayout="auto"
          />
        </div>

        <div className="section-pagination flex justify-center md:block px-2 py-8 md:p-4">
          <CustomPagination {...params} onPageChange={onPageChange} total={total} />
        </div>
      </div>

      <EditUser
        row={selectedRow}
        open={modal.isEditUserModalOpen}
        toggle={() => {
          toggleModal('isEditUserModalOpen')
          getData()
        }}
      />
    </>
  )
}

export default UserList
